import React from 'react'

const Footer = () => {
  return (
    <div className=' container footer'>
        <label>This Movies Web application is created by Toluwalase</label>
    </div>
  )
}

export default Footer