import "./App.css";
import {useEffect, useState} from 'react';
import SearchIcon from './search.svg'
import MovieCard from "./MovieCard";
import Footer from "./Footer";

const API_URL = "https://www.omdbapi.com?apikey=19f7f0a4";

function App() { 
  const [movies, setMovies] = useState([]); 
  const [searchTerm, setSearchTerm] = useState('');

  const searchMovies = async (title) =>{
    const response = await fetch(`${API_URL}&s=${title}`);
    const data = await response.json();
    setMovies(data.Search)
  }

  const handleEnter = (event) =>{

    if(event.key === 'Enter'){
      searchMovies(searchTerm);
    }
  }


  useEffect(() =>{
    
  }, [])

  return (
    <>
    <div className="app">
      <h1>Movie Land</h1>

      <div className="search">
        <input placeholder="Search for Movies" value={searchTerm} onChange={(e) =>{setSearchTerm(e.target.value)}} onKeyDown={(event) =>{handleEnter(event)}} />
        <img src={SearchIcon} alt="search" onClick={() =>{searchMovies(searchTerm)}} />

      </div>


    {
      movies.length > 0 ? 
      (
        <div className="container">
          {movies.map((movie, index) => (
            <MovieCard key={index} movie={movie} />

          ))}
      </div>
      ) :
      (
        <div className="empty">
          <h2>Search for a Movie in the search bar</h2>
        </div>
      )
      
    }

      

    </div>
    
    <Footer />
    </>
  );
}

export default App;
